<template>
  <main class="content home">
    <div class="inner mobile">
      <right-column :scheduled="scheduled" :history="history" :mobile="true"></right-column>
    </div>
    <div class="inner content-grid">
      <div class="left step5 tourpad">
        <page-heading
          heading="Cash Order Management"
          icon="cash-order-management-o.png"
          para="Manage existing cash orders and create new ones"
        ></page-heading>

        <div class="blocks">
          <router-link class="block-link step6" to="/order-cash-delivery">
            <span
              ><span><img src="/images/icons/order-cash-for-delivery-o.png" alt="Order Cash for Delivery" /></span
            ></span>
            <span
              ><span>Order Cash<br />for Delivery</span></span
            >
          </router-link>
          <router-link class="block-link" to="/request-ad-hoc-delivery" v-if="adHocDelivieriesAllowed">
            <span
              ><span><img src="/images/icons/order-cash-for-delivery-o.png" alt="Request Ad-Hoc Delivery" /></span
            ></span>
            <span
              ><span>Request Ad-Hoc<br />Delivery</span></span
            >
          </router-link>
          <!-- <router-link class="block-link" to="/request-ad-hoc-collection" v-if="adHocCollectionsAllowed">
            <span
              ><span><img src="/images/icons/order-cash-for-delivery-o.png" alt="Request Ad-Hoc Collection" /></span
            ></span>
            <span
              ><span>Request Ad-Hoc<br />Collection</span></span
            >
          </router-link> -->
          <!-- <router-link class="block-link" to="/scheduled-orders">
            <span><span><img src="/images/icons/update-password-o.png" alt="Scheduled Orders" /></span></span>
            <span><span>Scheduled<br />Orders</span></span>
          </router-link> -->
          <router-link class="block-link" to="/order-history">
            <span
              ><span><img src="/images/icons/system-log-out-o.png" alt="Order History" /></span
            ></span>
            <span
              ><span>Order<br />History</span></span
            >
          </router-link>
        </div>

        <Orders-Table />
      </div>
      <div class="hide-cutoff-mobile">
        <right-column :scheduled="scheduled" :history="history"></right-column>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"
import OrdersTable from "../components/OrdersTable.vue"

export default {
  components: {
    OrdersTable
  },
  data() {
    return {
      scheduled: [],
      history: []
    }
  },
  created() {
    api.request("get", "order?type=scheduled").then(res => {
      this.scheduled = res.orders
    })
    api.request("get", "order?type=history").then(res => {
      this.history = res.orders
    })
  },
  computed: {
    adHocDelivieriesAllowed() {
      if (this.$store.state.user_type == "hq-as-customer") {
        return this.$store.state.hq_user.hq_ad_hoc_deliveries
      }

      return this.$store.state.customer.site_ad_hoc_deliveries
    },
    adHocCollectionsAllowed() {
      if (this.$store.state.user_type == "hq-as-customer") {
        return this.$store.state.hq_user.hq_ad_hoc_collections
      }

      return this.$store.state.customer.site_ad_hoc_collections
    }
  },
  mounted() {
    if (this.$store.state.customer.first_visit || this.$route.query.showtour) {
      setTimeout(() => {
        var steps = [
          {
            element: document.querySelector(".step5"),
            scrollTo: "tooltip",
            intro:
              "In this section of the Portal, you get a set of options specific to the management of your cash orders. The main ‘Order Cash for Delivery’ is for placing new cash orders.<br /><Br />You can also view your ‘Scheduled Orders’ and ‘Order History’ using the relevant buttons."
          },
          {
            element: document.querySelector(".step6"),
            intro: "Let’s look at the ‘Order Cash for Delivery’ section to see the options available to you."
          }
        ]

        this.$intro()
          .setOptions({
            steps,
            nextLabel: "Next &rarr;",
            prevLabel: "&larr; Back",
            doneLabel: "Next &rarr;",
            exitOnOverlayClick: false
          })
          .start()
          .oncomplete(() => {
            window.location.replace("/order-cash-delivery?showtour=true")
          })
      }, 750)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
